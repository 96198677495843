<template>
  <div>
    <b-modal id="modal-prescription" size="xl" title="Pilih Resep" hide-footer>
      <Table
        :items="items"
        :fields="fields"
        :hasPatient="false"
        :selectPrescription ="true"
        :perPage="perPage"
        :currentPage="currentPage"
        :totalRows="totalRows"
        @filterByPeriode="generalFilter"
        @filterByDoctor="generalFilter"
        @filterByPatient="generalFilter"
        @btnDeleteOnClick="btnDeleteOnClick"
        @pageOnClick="pageOnClick"
        @selectedItem="setSelectedItem"
      />
    </b-modal>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Table from "@/component/prescriptions/Table.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  components: {
    Card,
    Table,
  },

  props:{
    source:Number
    // source :
    // 0 = pembayaran
    // 1 = apotek
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
        // start_date: new Date().toISOString().substr(0, 10),
        // end_date: new Date().toISOString().substr(0, 10),
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        // {
        //   key: "display_medicine",
        //   label: "Obat",
        //   sortable: true,
        // },
        { key: "actions", label: "Aksi", thClass: "th-action-prescription" },
      ],
      // Table Items
      items: [],
      // current user
      currentUser: getUser(),
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async setSelectedItem(data,id){
      this.$emit("setSelectedItem", data, id);
    },

    async pagination() {
      let route;
      if (this.currentUser.user_type_id == 1) {
        if(this.source == 0){
          route = `prescriptions/get-by-doctor/${this.currentUser.id}?redeem_type=0`;
        }else{
          route = `prescriptions/get-by-doctor/${this.currentUser.id}?redeem_type=0`;
        }
      } else {
        if(this.source == 0){
          route = "prescriptions?redeem_type=0";
        }else{
          route = "prescriptions?redeem_type=1";
        }
      }
      let filterParams = `&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.patient_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    generalFilter(sended) {
      this.filter.doctor_id = sended.doctor_id;
      this.filter.start_date = sended.start_date;
      this.filter.end_date = sended.end_date;
      this.filter.patient_name = sended.patient_name;
      this.pagination();
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("prescriptions/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },
  },

  mounted() {
    // Get Table Data
    this.pagination();
  },
};
</script>